import React from "react";
import Login from "./Login";
import messages from "./messages";
import { setCurrentRouteName } from "../../reducers/global";
import { logout } from "../../reducers/user";

function action({ intl, query, store, route }) {
  logout();
  store.dispatch(setCurrentRouteName(route.name));

  return {
    chunks: ["login"],
    title: intl.formatMessage(messages.title),
    component: (
      <React.Fragment>
        <Login redirectUrl={query.next} params={query} intl={intl} />
      </React.Fragment>
    ),
  };
}

export default action;
